import { patchCall } from '../request';

const PutGDPRFormService = (id) => {
  const access_token = localStorage.getItem('token');

  return patchCall({
    url: `${process.env.REACT_APP_API_SERVICES_BASE_URL}/gdpr-form/${id}/set-default`,
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    timeout: 60 * 1000
  });
};

export default PutGDPRFormService;
