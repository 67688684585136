/* eslint-disable react-hooks/exhaustive-deps */
import en from 'world_countries_lists/data/countries/en/world.json';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd-country-phone-input';

import VerticalLayout from './layout/vertical/Vertical';
// import HorizontalLayout from './layout/horizontal/Horizontal';
import NotFound from './pages/sessions/404';

import './App.scss';
import { defaultRoutes, sessionRoutes, userRoutes, lawyerRoutes, corporateRoutes , secretaryRoutes } from './routing';
import { ProtectedRoute } from './utils/protected-root';

import { useHideLoader } from './hooks';
import { IUserInfo } from './interfaces';
import { LanguageProvider } from './translation';

import './App.scss';

type Props = {
  routes: any;
  layout?: string;
  type?: string | null;
};

const Routes = ({ routes, layout = '', type }: Props) => {
  const access_token = localStorage.getItem('token');
  return (
    <Switch>
      {routes.map((route, index) => {
        switch (type) {
          case 'user':
            return (
              <ProtectedRoute
                key={index}
                path={`/${route.path}`}
                component={route.component}
                access_token={access_token}
              />
            );

          default:
            return (
              <Route
                key={index}
                exact={route.exact}
                path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}`}
                component={() => <route.component />}
              />
            );
        }
      })}
      <Route path='*'>
        <Redirect to='/public/page-404' />
      </Route>
    </Switch>
  );
};

const DefaultRoutes = ({ layout }) => <Routes type='user' routes={defaultRoutes} layout={layout} />;
const UserRoutes = ({ layout }) => <Routes type='user' routes={userRoutes} layout={layout} />;
const CorporateRoutes = ({ layout }) => <Routes type='user' routes={corporateRoutes} layout={layout} />;
const LawyerRoutes = ({ layout }) => <Routes type='user' routes={lawyerRoutes} layout={layout} />;
const SecretaryRoutes = ({ layout }) => (
  <Routes type='user' routes={secretaryRoutes} layout={layout} />
);
const SessionRoutes = () => <Routes type={null} routes={sessionRoutes} layout='public' />;

const App = () => {
  useHideLoader();
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);
  return (
    <LanguageProvider>
      <Switch>
        <Route path='/' exact>
          <Redirect to='/main-page' />
        </Route>
        <Route path='/public'>
          <SessionRoutes />
        </Route>
        <ConfigProvider locale={en}>
          <Route path='/'>
            <VerticalLayout>
              {userInfo.role === 'admin' && <DefaultRoutes layout='vertical' />}
              {userInfo.role === 'lawyer' && <LawyerRoutes layout='vertical' />}
              {userInfo.role === 'secretary' && <SecretaryRoutes layout='vertical' />}
              {userInfo.role === 'user' && <UserRoutes layout='vertical' />}
              {userInfo.role === 'corporate' && <CorporateRoutes layout='vertical' />}
              {userInfo.role === '' && <DefaultRoutes layout='vertical' />}
            </VerticalLayout>
          </Route>
        </ConfigProvider>
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </LanguageProvider>
  );
};

export default App;
