/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Table,
  Tag,
  Tooltip
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { NotificationPlacement } from 'antd/lib/notification';

import GetConsentService from '../../../api/consent-form/get-consent';
import GetConsentByIdService from '../../../api/consent-form/get-consent-by-id';
import GetSurrogateService from '../../../api/surrogate/get-surrogate';

import { calculate_age } from '../../../utils/ageCalculate';
import {
  DisplayPhoneNumber,
  isForeignNumber
} from '../../../layout/components/phoneFormat/PhoneFormat';

import { IConsentForm, IPatient, ISubtenantForm, ISurrogate, IUserInfo } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

import './ConsentFormsTable.scss';
import GetSubtenantsByIdService from '../../../api/subtenant/get-subtenant';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IConsentFormsTable {
  patient?: IPatient;
  perPage?: number;
  isProcess?: boolean;
  sendConsent?: (consent: any, surrogateId: string, consentType: string, subTenantId: string) => void;
}
const { Option } = Select;
const ConsentFormsTable = ({
  patient = null,
  perPage = 10,
  isProcess = false,
  sendConsent
}: IConsentFormsTable) => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const updated = useSelector((state: any) => state.updated);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IConsentForm[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [state, setState] = useState({
    numPages: null,
    fileUrl: ''
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [visible, setVisible] = useState(false);
  const [surrogates, setSurrogates] = useState<ISurrogate[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const [selectedConsentType, setSelectedConsentType] = useState<string>('approve');
  const [doctors, setDoctors] = useState<ISubtenantForm[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<ISubtenantForm>(null);
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);

  const selectDoctor = (doctorId: string) => {
    const tappedDoctor = doctors.find((doctor) => {
      doctor.id = doctorId;
    });
    if (tappedDoctor) {
      setSelectedDoctor(tappedDoctor);
    }
  };

  const onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    setState((state) => ({ ...state, numPages: numPages }));
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const getConsent = (id) => {
    GetConsentByIdService(id).then((res) => {
      window.open(res?.file?.url, '_blank');
      setState((state) => ({ ...state, fileUrl: res?.file?.url }));
    });
  };
  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };
  const { numPages, fileUrl } = state;
  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t.patient_operations_send_consent_popup_table_column_consent_name_text,
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'isLatest',
      dataIndex: 'isLatest',
      title: t.patient_operations_send_consent_popup_table_column_consent_status_text,
      render: (isLatest) => (
        <strong>
          {isLatest ? (
            <Tag color='#299617'>
              {t.patient_operations_send_consent_popup_consent_uptodate_text}
            </Tag>
          ) : null}
        </strong>
      )
    },
    {
      key: 'version',
      dataIndex: 'version',
      title: t.patient_operations_send_consent_popup_table_column_consent_version_text,
      render: (version) => <strong>{version}</strong>
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t.patient_operations_send_consent_popup_table_column_consent_upload_date_text,
      render: (createdAt) => <strong>{createdAt && moment(createdAt).format('DD/MM/YYYY')}</strong>
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: t.patient_operations_send_consent_popup_table_column_consent_operations_text,
      render: (id) => {
        return (
          <strong>
            <Modal
              width={'80%'}
              visible={visible}
              footer={null}
              onCancel={() => setVisible(false)}
              destroyOnClose
              title={
                <span style={{ fontSize: '1.5em' }}>
                  {t.patient_operations_patient_consents_popup_table_column_view_consent_text}
                </span>
              }
            >
              <div id='pdfWrapper' className='pdf-wrapper'>
                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>

              <div className='pdf-page-button-wrapper'>
                <p>
                  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </p>
                <Button disabled={pageNumber <= 1} onClick={previousPage}>
                  Previous
                </Button>
                <Button disabled={pageNumber >= numPages} onClick={nextPage}>
                  Next
                </Button>
              </div>
            </Modal>
            <Tooltip
              title={t.patient_operations_patient_consents_popup_table_column_view_consent_text}
            >
              <Button type='primary' shape='circle' onClick={() => getConsent(id)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
          </strong>
        );
      }
    }
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    }
  };
  const [filterText, setFilterText] = useState('');

  //fonksiyonlardan önce kurulum
  let typingTimer; //zamanlayıcı tanımlayıcısı

  //keyup'ta geri sayımı başlat
  const onKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleGetConsent, 500);
  };

  //tuşa basıldığında, geri sayımı temizle
  const onKeyDown = () => {
    clearTimeout(typingTimer);
  };

  const pagination = {
    pageSize: itemsPerPage,
    current: currentPage,
    total: totalItems,
    showSizeChanger: false
  };

  useEffect(() => {
    setLoading(true);
    handleGetConsent();
  }, [updated, currentPage]);

  useEffect(() => {
    if (!patient) {
      return;
    }
    setSelectedDoctor(doctors[0]);
  }, [doctors]);

  useEffect(() => {
    if (userInfo.role === 'corporate') {
      setLoading(true);
      GetSubtenantsByIdService()
        .then((res) => {
          setDoctors(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }

    if (!patient) {
      return;
    }
    setLoading(true);
    GetSurrogateService(patient.id)
      .then((res) => {
        setSurrogates(res);
      })
      .finally(() => setLoading(false));
  }, [updated]);

  useEffect(() => {
    if (!patient) {
      return;
    }
    if (calculate_age(patient.birthDay) < 18 && surrogates && surrogates.length > 0) {
      setSelectedPerson(surrogates[0].id);
    }
  }, [surrogates]);

  const handleGetConsent = () => {
    if (filterText.length > 2 || filterText.length < 1) {
      const params = {
        search: filterText,
        'filter.isLatest': 1,
        'filter.type': 'approve',
        page: currentPage,
        limit: 9
      };
      if (!patient) {
        delete params['filter.type'];
      }
      GetConsentService(params)
        .then((res) => {
          setItemsPerPage(res?.meta?.itemsPerPage);
          setTotalItems(res?.meta?.totalItems);
          setTableData(res.data);
        })
        .catch((err) => {
          openNotification(
            'bottomRight',
            'error',
            err?.response?.data?.message ?? t.notification_error_consents_loaded,
            ''
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const DoctorSelectionArea = () => {
    return (
      <>
        {contextHolder}
        {selectedDoctor && userInfo.role === 'corporate' ? (
          <div className='consent_choice_container'>
            <div className='consent_choice_row'>
              <h6 className='consent_choice_label'>Doktor Tercihi</h6>
              <Form.Item className='consent_choice_form_item'>
                <Select
                  placeholder='Form gönderilecek kişiyi seçin'
                  value={selectedDoctor.id}
                  optionFilterProp='children'
                  className='mb-2 mb-md-0'
                  onSelect={(value, option) => {
                    selectDoctor(value);
                  }}
                >
                  {doctors.map((doctor) => {
                    return <Option value={doctor.id}>{doctor.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div>
      {patient ? (
        <>
          <div className='consent_choice_container'>
            <div className='consent_choice_row'>
              <h6 className='consent_choice_label'>
                {t.patient_operations_send_consent_popup_contact_choice_text}
              </h6>
              <Form.Item className='consent_choice_form_item'>
                <Select
                  placeholder='Form gönderilecek kişiyi seçin'
                  value={selectedPerson}
                  optionFilterProp='children'
                  className='mb-2 mb-md-0'
                  onSelect={(value, option) => {
                    setSelectedPerson(value);
                  }}
                >
                  {calculate_age(patient.birthDay) < 18 ? (
                    <></>
                  ) : (
                    <Option value=''>
                      {patient?.firstName} {patient?.lastName} /{' '}
                      {isForeignNumber(patient?.gsm)
                        ? patient?.email
                        : DisplayPhoneNumber(patient?.gsm)}
                    </Option>
                  )}
                  {surrogates.map((surrogate) => {
                    return (
                      <Option value={surrogate.id}>
                        {surrogate.firstName} {surrogate.lastName} /{' '}
                        {isForeignNumber(surrogate?.gsm)
                          ? surrogate?.email
                          : DisplayPhoneNumber(surrogate?.gsm)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className='consent_choice_row'>
              <h6 className='consent_choice_label'>
                {t.patient_operations_send_consent_popup_consent_type_text}
              </h6>
              <Form.Item className='consent_choice_form_item'>
                <Select
                  placeholder='Onam amacını seçin'
                  value={selectedConsentType}
                  optionFilterProp='children'
                  className='mb-2 mb-md-0'
                  onSelect={(value, option) => {
                    setSelectedConsentType(value);
                  }}
                >
                  <Option value='approve'>
                    {t.patient_operations_send_consent_popup_consent_type_approve_text}
                  </Option>
                  <Option value='reject'>
                    {t.patient_operations_send_consent_popup_consent_type_reject_text}
                  </Option>
                  <Option value='cancel'>
                    {t.patient_operations_send_consent_popup_consent_type_cancel_text}
                  </Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <DoctorSelectionArea />
        </>
      ) : (
        <></>
      )}
      <Divider className='divider' />

      <div style={{ maxWidth: 320, margin: '0 0 12px 0' }}>
        <Tooltip title={t.general_search_tip_text}>
          <Input
            type='text'
            id='searchInput'
            placeholder={t.patient_operations_send_consent_popup_consent_search_text}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Tooltip>
      </div>
      {contextHolder}
      <Table
        rowSelection={
          isProcess
            ? {
                type: 'radio',
                ...rowSelection
              }
            : undefined
        }
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={tableData}
        onChange={(pagination) => {
          setCurrentPage(pagination.current);
        }}
        columns={columns}
        loading={loading}
      />
      <Divider />

      {isProcess && (
        <Button
          loading={loading}
          onClick={() => {
            setLoading(true);
            sendConsent(selectedRows, selectedPerson, selectedConsentType, selectedDoctor ? selectedDoctor.id : null);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }}
        >
          {t.patient_operations_send_consent_popup_send_text}
        </Button>
      )}
    </div>
  );
};

export default ConsentFormsTable;
