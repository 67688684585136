import { IRoute } from '../interfaces';
import Error from '../pages/dashboards/payments/Error';
import Successful from '../pages/dashboards/payments/Successful';
import GdprAndConsentPdfView from '../pages/gdprAndConsentPdfView/GdprAndConsentPdfView';
import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import MailRedirect from '../pages/sessions/MailRedirect';
import ForgotPassword from '../pages/sessions/password/ForgotPassword';
import SendMailForForgotPassword from '../pages/sessions/password/SendMailForForgotPassword';
import SignIn from '../pages/sessions/Sign-in';
import SignUp from '../pages/sessions/Sign-up';

export const sessionRoutes: IRoute[] = [
  {
    path: 'page-404',
    component: NotFound
  },
  {
    path: 'page-500',
    component: InternalError
  },

  { path: 'mail-forgot-password', component: SendMailForForgotPassword },
  { path: 'mail/reset/verify/:token', component: ForgotPassword },
  { path: 'sign-up/subuser/:mail/:token', component: SignUp },
  { path: 'mail/verify/invitation/:token', component: MailRedirect },
  {
    path: 'sign-up',
    component: SignUp
  },
  {
    path: 'sign-in',
    component: SignIn
  },
  {
    path: 'gdpr/:code',
    component: GdprAndConsentPdfView
  },
  {
    path: 'consent/:code',
    component: GdprAndConsentPdfView
  },
  {
    path: 'payment-success',
    component: Successful
  },
  {
    path: 'payment-error',
    component: Error
  }
];
