/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataUpdated } from '../../../redux/data-updated/action';
import moment from 'moment';

import { Button, notification, Table, Tag, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { NotificationPlacement } from 'antd/es/notification';

import GetGDPRFormService from '../../../api/gdpr-form/get-gdpr-form';
import PutGDPRFormService from '../../../api/gdpr-form/put-gdpr-form';
import GetGDPRFormById from '../../../api/gdpr-form/get-gdpr-form-id';

import { IKvkkForm, IUserInfo } from '../../../interfaces';
import { LanguageContext, translations } from '../../../translation';

const KvkkFormsTable = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [api, contextHolder] = notification.useNotification();
  const updated = useSelector((state: any) => state.updated);
  const dispatch = useDispatch();
  const userInfo: IUserInfo = useSelector((state: any) => state.userInfo);
  const [forms, setForms] = useState<IKvkkForm[]>([]);

  const [loading, setLoading] = useState(false);

  const openNotification = (placement: NotificationPlacement, type, message, description) => {
    api[type]({
      message,
      description,
      placement
    });
  };
  const getGdpr = (id) => {
    GetGDPRFormById(id)
      .then((res) => {
        window.open(res?.file?.url, '_blank');
      })
      .catch((err) => err);
  };
  const actions = (kvkk) => {
    //refaktör edilecek -renk sorguları-
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {kvkk.isLatest ? (
          <Tag
            style={{ width: 100, display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
            color={!kvkk.isLatest ? '#336cfb' : '#299617'}
          >
            {t.gdpr_forms_table_active_text}
          </Tag>
        ) : userInfo?.roles === 'admin' ? (
          <Tag
            style={{ width: 100, display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
            color={!kvkk.isLatest ? '#336cfb' : '#299617'}
            onClick={() => {
              PutGDPRFormService(kvkk?.id)
                .then((res) => {
                  dispatch(setDataUpdated(!updated));
                })
                .catch((err) => console.log(err));
            }}
          >
            {t.gdpr_forms_table_activate_text}
          </Tag>
        ) : (
          <Tag
            style={{ width: 100, display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
            color={!kvkk.isLatest ? '#336cfb' : '#299617'}
          >
            {t.gdpr_forms_table_expired_text}
          </Tag>
        )}
        <Tooltip title={t.gdpr_forms_tips_view_text}>
          <Button
            type='primary'
            shape='circle'
            style={{ marginLeft: 8 }}
            onClick={() => getGdpr(kvkk?.id)}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);
    GetGDPRFormService()
      .then((res) => {
        setForms(res.data);
        openNotification('bottomRight', 'success', t.notification_successful_gdpr_loaded, '');
      })
      .catch((err) => {
        openNotification(
          'bottomRight',
          'error',
          err?.response?.data?.message ?? t.notification_error_gdpr_loaded,
          ''
        );
      })
      .finally(() => setLoading(false));
  }, [updated]);

  const columns: ColumnProps<IKvkkForm>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t.gdpr_forms_table_column_name_text,

      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'version',
      dataIndex: 'version',
      title: t.gdpr_forms_table_column_version_text,
      render: (version) => <strong>{version}</strong>
    },
    {
      key: 'createdBy',
      // dataIndex: 'version',
      title: t.gdpr_forms_table_column_creator_text,
      render: () => <strong>Admin</strong>
    },
    {
      key: 'createdDate',
      // dataIndex: 'version',
      title: t.gdpr_forms_table_column_creation_date_text,
      render: (date) => <strong>{moment(date?.createdAt).format('DD/MM/YYYY')}</strong>
    },
    {
      key: 'actions',
      title: t.gdpr_forms_table_column_operations_text,
      render: actions
    }
  ];

  const pagination = forms.length < 8 ? false : { pageSize: 8 };

  return (
    <>
      {contextHolder}
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={forms}
        columns={columns}
        loading={loading}
      />
    </>
  );
};

export default KvkkFormsTable;
